// libraries
import _ from 'lodash'
import keymirror from 'keymirror'
import { EnumType } from 'json-to-graphql-query'

// constants
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'
import { DATASET_TYPES } from 'constants/unipipe'
import { LAYER_TYPES } from 'constants/map'
import { LAYER_PROFILE_TYPES } from 'constants/profile'
import {
  ISSUE_FORM_FILTER_TYPE,
  PROPERTY_VARIABLE_FORMATS,
} from 'constants/filter'

export const ISSUE_STATUS = keymirror({
  open: null,
  closed: null,
  data_collection: null,
  sub_tasks: null,
})

export const ISSUE_FILTER = keymirror({
  modifiedAfter: null,
  modifiedBefore: null,
  status: null,
  severity: null,
  subjectReference: null,
  triggerReference: null,
  type: null,
  deleted: null,
})

const iconBgOpacityRadio = 8

export const DEFAULT_ISSUE_MAP_LAYER_SETTINGS = {
  name: 'issue',
  fillColour: [255, 255, 255, 0],
  radius: 20,
  isVisible: true,
  iconPosition: { x: 288, y: 864 },
  bgColour: [255, 255, 255, (iconBgOpacityRadio / 100) * 255],
  timeliness: DATASET_TYPES.historical,
  type: LAYER_TYPES.icon,
  profile: { type: LAYER_PROFILE_TYPES.issue },
}

export const CUSTOMIZED_FILTERS_KEYS = [
  GALLERY_LIST_FILTER_TYPES.status,
  GALLERY_LIST_FILTER_TYPES.severity,
  GALLERY_LIST_FILTER_TYPES.modifiedDatetime,
  GALLERY_LIST_FILTER_TYPES.assignedToUsername,
  ISSUE_FORM_FILTER_TYPE,
]

export const ISSUE_FILTER_FIELDS = {
  [ISSUE_FILTER.modifiedAfter]: 'MODIFIED_AFTER',
  [ISSUE_FILTER.type]: 'TYPE',
  [ISSUE_FILTER.status]: 'STATUS',
  [ISSUE_FILTER.severity]: 'SEVERITY',
  [ISSUE_FILTER.deleted]: 'DELETED',
  [GALLERY_LIST_FILTER_TYPES.assignedToUsername]: 'ASSIGNED_TO_USERNAME',
}

export const DEFAULT_QUERY_OPTIONS = {
  notifyOnNetworkStatusChange: true,
}

export const ISSUE_ACTION_TYPES = keymirror({
  updateIssue: null,
  commentIssue: null,
  closeIssue: null,
  transitionIssueState: null,
  assignIssueToUser: null,
  modifyIssueComment: null,
  deleteIssueComment: null,
  acknowledgeAssignment: null,
  bulkUpdateIssues: null,
})

export const ISSUE_TYPES = keymirror({
  ISSUE: null,
  TASK: null,
  MULTITASK: null,
  SUBTASK: null,
})

export const DEFAULT_POLL_INTERVAL = 120 * 1000

export const ISSUE_STATUS_IN_PROGRESS = 'In progress'

export const ISSUE_SUB_TASK_STATUS_CLOSED_LABEL = 'Done'

const ISSUE_ANNOTATIONS_FRAGMENT = {
  annotations: {
    text: true,
    createdAt: true,
    authorReference: true,
    modifiedAt: true,
    deleted: true,
    index: true,
  },
}

const ISSUE_TRIGGER_FRAGMENT = {
  trigger: {
    triggerContext: {
      reference: true,
      user: {
        id: true,
      },
      rule: {
        id: true,
        name: true,
      },
    },
    location: {
      type: true,
      coordinates: true,
    },
  },
}

const ISSUE_STATE_DATA_COLLECTION_DATA = {
  __typeName: 'IssueStateDataCollectionData',
  __typename: true,
  dataCollectionFormComplete: true,
  dataCollectionResponses: true,
  dataCollectionFormMedia: {
    __typename: true,
    mediaKey: true,
    downloadUrl: true,
  },
  dataCollectionFormResult: true,
  dataCollectionResponsesViews: {
    __typename: true,
    downloadUrl: true,
  },
}

const ISSUE_STATE_DATA_COLLECTION_PARAMETER = {
  __typeName: 'IssueStateDataCollectionParameter',
  dataCollectionFormReference: true,
}

const ISSUE_STATE_SUB_TASKS_PARAMETER = {
  __typeName: 'IssueStateSubTasksParameter',
  status: true,
  multitaskProcedureId: true,
}

const ISSUE_STATES_PARAMETER_DATA = {
  statesParameter: {
    __on: [
      ISSUE_STATE_DATA_COLLECTION_PARAMETER,
      ISSUE_STATE_SUB_TASKS_PARAMETER,
    ],
  },
}

export const BASE_ISSUE = {
  id: true,
  title: true,
  type: true,
  description: true,
  lastModifiedAt: true,
  openedAt: true,
  pastDue: true,
  dueAt: true,
  severity: true,
  status: true,
  deleted: true,
  subject: {
    reference: true,
    assetReference: {
      asset: {
        displayName: true,
        observation: true,
        id: true,
        profile: true,
      },
    },
  },
  watchers: {
    email: true,
  },
  closedAt: true,
  taskType: true,
  owner: {
    username: true,
    group: true,
  },
}

const ISSUE_STATES_DATA = {
  statesData: {
    __on: [
      ISSUE_STATE_DATA_COLLECTION_DATA,
      {
        __typeName: 'IssueStateSubTasksData',
        subTasksReferences: {
          issue: {
            ..._.pick(BASE_ISSUE, [
              'id',
              'type',
              'title',
              'status',
              'lastModifiedAt',
              'deleted',
              'taskType',
            ]),
            subject: {
              assetReference: {
                asset: {
                  displayName: true,
                },
              },
            },
            statesData: {
              __on: ISSUE_STATE_DATA_COLLECTION_DATA,
            },
            ...ISSUE_STATES_PARAMETER_DATA,
          },
        },
      },
    ],
  },
}

const ISSUE_ASSIGNEE_FRAGMENT = {
  assignee: {
    __on: {
      __typeName: 'IssueUserAssignee',
      userReference: {
        userId: true,
      },
      acknowledged: true,
      acknowledgedAt: true,
    },
  },
}

export const ISSUE_DETAIL_FRAGMENT = {
  ...BASE_ISSUE,
  ...ISSUE_STATES_DATA,
  ...ISSUE_STATES_PARAMETER_DATA,
  ...ISSUE_TRIGGER_FRAGMENT,
  ...ISSUE_ANNOTATIONS_FRAGMENT,
  ...ISSUE_ASSIGNEE_FRAGMENT,
}

const ISSUE_FOR_EMISSION_OBSERVATION_STATES_PARAMETER_DATA = {
  statesParameter: {
    __on: [
      {
        __typeName: 'IssueStateDataCollectionParameter',
        dataCollectionFormReference: true,
        dataCollectionFormDetails: {
          id: true,
          title: true,
          description: true,
          formType: true,
          jsonFormBody: {
            uischema: true,
            schema: true,
          },
        },
      },
      ISSUE_STATE_SUB_TASKS_PARAMETER,
    ],
  },
}

export const ISSUE_FOR_EMISSION_OBSERVATION_FRAGMENT = {
  ...BASE_ISSUE,
  ...ISSUE_STATES_DATA,
  ...ISSUE_FOR_EMISSION_OBSERVATION_STATES_PARAMETER_DATA,
  ...ISSUE_TRIGGER_FRAGMENT,
  ...ISSUE_ANNOTATIONS_FRAGMENT,
  ...ISSUE_ASSIGNEE_FRAGMENT,
}

export const ISSUE_SEVERITY_TYPES_FIELDS = {
  value: { __aliasFor: 'severity' },
  label: true,
  colour: { __aliasFor: 'webColour' },
}

export const DEFAULT_ISSUE_SEVERITY_TYPES = [
  { value: 1, label: 'Low', colour: '#95D000' },
  { value: 2, label: 'Medium', colour: '#FCC300' },
  { value: 3, label: 'High', colour: '#FF8A00' },
  { value: 4, label: 'Critical', colour: '#E75150' },
]

export const ISSUE_LIST_ALLOWED_TYPES = [
  ISSUE_TYPES.TASK,
  ISSUE_TYPES.MULTITASK,
  ISSUE_TYPES.ISSUE,
]

export const ISSUE_TYPE_FILTER_DEFAULT_VALUE = _.map(
  ISSUE_LIST_ALLOWED_TYPES,
  type => new EnumType(type)
)

export const ISSUE_TYPE_FILTER_SUBTASKS_ONLY = [
  new EnumType(ISSUE_TYPES.SUBTASK),
]

export const ISSUE_FORM_CONTROL_TYPES = keymirror({
  INPUT: null,
  SELECT1: null,
  // non-supported types
  SELECT: null,
  UPLOAD: null,
  TRIGGER: null,
  RANGE: null,
  ODK_RANK: null,
})

export const X_FORM_DATA_FORMAT_TYPE = keymirror({
  string: null,
  int: null,
  boolean: null,
  decimal: null,
  date: null,
  time: null,
  dateTime: null,
  geopoint: null,
  geotrace: null,
  geoshape: null,
  binary: null,
  barcode: null,
  intent: null,
})

export const ISSUE_FORM_SPEC_REF_PREFIX = '/data/'

export const ISSUE_FORM_SPEC_REF_SEPARATOR = '/'

export const XFORM_BODY_UPLOAD_MEDIA_TYPE = {
  [PROPERTY_VARIABLE_FORMATS.image]: 'image/*',
  audio: 'audio/*',
  video: 'video/*',
}

export const ISSUE_TASK_DATA_COLLECTION_FORM_TYPES = keymirror({
  ODK_FORM: null,
  JSON_FORM: null,
})

export const ISSUE_TASK_TYPES = keymirror({
  COMPLETE_ODK_FORM: null,
  COMPLETE_JSON_FORM: null,
})

export const ISSUE_SUBJECT_TYPES = keymirror({
  asset: null,
  observation: null,
})
