// libraries
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import _ from 'lodash'
import {
  DataTable as ReactDataTable,
  DataTableRowGroupHeaderTemplateOptions,
  DataTableRowToggleEvent,
  DataTableSelectEvent,
} from 'primereact/datatable'
import { Column, type ColumnSortEvent } from 'primereact/column'

// constants
import { THEMES } from 'constants/colour'
import { BADGE_TYPES } from 'constants/common'

// utils
import { sortListByProperty } from 'helpers/utils'

// components
import { Badge } from 'components/common'
import EmptyColumnsList from 'components/common/List/EmptyColumnsList'

import type { Item } from 'types/common'

import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import { StyledDataTable } from './styles'
import {
  BodyActionsTemplate,
  BodySelectTemplate,
  GroupHeaderTemplate,
} from './CellTemplates'
import {
  INFINITE_SCROLL_THRESHOLD,
  ACTION_COLUMN_COMMON_OPTIONS,
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEIGHT,
} from './constants'
import {
  LoadingMoreSpinner,
  SelectColumnHeader,
  TableProgressBar,
} from './components'

import type { DataTableProps } from './types'

export type { DataTableProps }

const sortFunction = (event: ColumnSortEvent) => {
  const { data, field, order } = event
  return sortListByProperty({
    list: data,
    sortField: field,
    ascOrder: order === 1,
  })
}

/** Does just nothing */
const noopSort = ({ data }: ColumnSortEvent) => data

const DataTable = ({
  list: initialList = [],
  columns = [],
  allAvailableColumns,
  setVisibleColumns,
  sortField,
  sortOrder,
  backendSortEnabled = false,
  isMultiSort = false,
  primaryFields,
  itemActions = {},
  selectedIdsSet,
  selectedItemsActions,
  theme = THEMES.light,
  enableBulkEdit = false,
  enableActions = true,
  tableGroupedBy,
  fetchMore,
  isLoadingMore,
  hasNextPage,
  dataKey = 'id',
  isVirtualScroll = true,
  setListConditions,
  currentActiveItem,
  rowClassName,
  customActionColumn,
  scrollable = true,
  scrollHeight = 'flex',
  emptyMessage = 'No items',
  rowGroupMode,
  groupRowsBy,
  loading,
  resizableColumns = true,
  footer = null,
  pt,
  onCellEditComplete,
  className = 'position-relative w-100 h-100',
}: DataTableProps): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  const { onSelect, onView, onEdit, onMouseEnter, onMouseLeave } =
    itemActions || {}

  const [headerHeight, setHeaderHeight] = useState<number>(TABLE_HEADER_HEIGHT)

  const tableRef = useRef<ReactDataTable | null>(null)

  const getTheadHeight = () => {
    const theadElement = document.querySelector('.p-datatable-thead')
    if (theadElement) {
      const theadHeight = theadElement.offsetHeight
      setHeaderHeight(theadHeight)
    }
  }

  const debouncedGetTheadHeight = _.debounce(getTheadHeight, 100)

  const validColumnsSpecs = useMemo(() => {
    const commonProps = {
      headerStyle: { width: '3rem', textAlign: 'center' },
      bodyStyle: { textAlign: 'center', overflow: 'visible' },
      ...ACTION_COLUMN_COMMON_OPTIONS,
    }

    const selectColumnStyle = {
      justifyContent: 'center',
      maxWidth: '75px',
    }

    const selectColumn =
      enableBulkEdit && selectedItemsActions
        ? {
            ...commonProps,
            header: (
              <SelectColumnHeader
                list={initialList}
                selectedIdsSet={selectedIdsSet}
                selectedItemsActions={selectedItemsActions}
              />
            ),
            body: BodySelectTemplate(selectedItemsActions),
            headerStyle: {
              ...selectColumnStyle,
              height: '60px',
            },
            style: selectColumnStyle,
          }
        : undefined

    const actionColumn =
      customActionColumn ||
      (enableActions && !_.isEmpty(_.omit(itemActions, 'onChange'))
        ? {
            ...commonProps,
            body: BodyActionsTemplate(itemActions),
          }
        : undefined)

    return _.compact([selectColumn, ...columns, actionColumn])
  }, [
    initialList,
    columns,
    enableActions,
    enableBulkEdit,
    itemActions,
    selectedIdsSet,
    selectedItemsActions,
    customActionColumn,
  ])

  const isCellEditable = useMemo(
    () => _.find(validColumnsSpecs, ({ editor }) => !_.isNil(editor)),
    [validColumnsSpecs]
  )

  const [list, setList] = useState<unknown[]>([])

  useEffect(() => {
    // setList(structuredClone(initialList))
    setList(initialList)
  }, [initialList])

  useEffect(() => {
    getTheadHeight()

    window.addEventListener('resize', debouncedGetTheadHeight)

    return () => {
      window.removeEventListener('resize', debouncedGetTheadHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [expandedRows, setExpandedRows] = useState<Item[]>([])

  useEffect(() => {
    if (tableGroupedBy && !_.isEmpty(expandedRows)) {
      // when tableGroupedBy changed, clear out the current expanded rows
      setExpandedRows([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableGroupedBy])

  useEffect(() => {
    // this cannot be done in a dynamic fashion because true rendered values of elements
    // are not being reported until after we need it, possibly because the table is using
    // virtualization
    if (hasNextPage && !isLoadingMore && fetchMore) {
      const scroller = tableRef?.current
        ?.getVirtualScroller()
        ?.getElementRef().current

      if (
        scroller &&
        list.length * TABLE_ROW_HEIGHT < scroller.clientHeight - headerHeight
      ) {
        fetchMore()
      }
    }
  }, [list, hasNextPage, isLoadingMore, fetchMore, headerHeight])

  const renderColumns = useCallback(() => {
    return _.map(validColumnsSpecs, col => {
      const {
        header,
        field,
        bodyStyle,
        style,
        key,
        sortField: colSortField,
      } = col
      const columnKey = field ?? key ?? header

      return (
        <Column
          key={`${columnKey}-${header}`}
          {..._.omit(col, 'key')}
          // !group by can't work together with sort
          {...(tableGroupedBy
            ? { sortable: false }
            : {
                sortField: colSortField || field,
                // Just do nothing if items will be sorted on the backend side
                sortFunction: backendSortEnabled ? noopSort : sortFunction,
              })}
          bodyStyle={
            _.includes(primaryFields, header)
              ? { fontWeight: 'bold' }
              : bodyStyle
          }
          style={style}
          {...(onCellEditComplete && { onCellEditComplete })}
        />
      )
    })
  }, [
    validColumnsSpecs,
    tableGroupedBy,
    backendSortEnabled,
    primaryFields,
    onCellEditComplete,
  ])

  const onRowSelect = useCallback(
    (e: DataTableSelectEvent) => {
      const select = onSelect || onView || onEdit
      if (!_.isFunction(select)) return

      select(e.data)
    },
    [onEdit, onSelect, onView]
  )

  const dataGroupedBy = useMemo(() => {
    return tableGroupedBy
      ? _(list).groupBy(tableGroupedBy).mapValues(_.size).value()
      : {}
  }, [list, tableGroupedBy])

  const rowGroupHeaderTemplate = useCallback(
    (
      data: Item,
      groupHeaderOptions: DataTableRowGroupHeaderTemplateOptions
    ) => {
      // It's important to set this boolean to 'true'
      // For the reference: https://github.com/primefaces/primereact/commit/161cdccc1a779c17e3c4e94d6f029b31fbba6971
      groupHeaderOptions.customRendering = true

      const columnSpec = _.find(
        allAvailableColumns,
        ({ field, groupByField }) =>
          field === tableGroupedBy || groupByField === tableGroupedBy
      )
      const template = columnSpec?.body

      const content = template ? (
        template(data, columnSpec)
      ) : (
        <span>{_.get(data, tableGroupedBy || '')}</span>
      )

      const total = dataGroupedBy[_.get(data, tableGroupedBy)]

      return (
        <GroupHeaderTemplate
          data={data}
          dataKey={dataKey}
          allColumnsCount={validColumnsSpecs.length}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
        >
          {content}
          <div>
            <Badge
              content={total}
              type={BADGE_TYPES.infoGrey}
              className='ms-2'
            />
          </div>
        </GroupHeaderTemplate>
      )
    },
    [
      dataKey,
      tableGroupedBy,
      allAvailableColumns,
      dataGroupedBy,
      validColumnsSpecs.length,
      expandedRows,
    ]
  )

  const onInfiniteScroll = useCallback(
    (e: React.UIEvent<HTMLElement, UIEvent>) => {
      const {
        scrollHeight: scrollableHeight,
        scrollTop,
        offsetHeight,
      } = e.target as HTMLDivElement
      const pxLeftToBottom = scrollableHeight - scrollTop - offsetHeight

      const shouldLoadMore =
        hasNextPage && pxLeftToBottom <= INFINITE_SCROLL_THRESHOLD

      if (shouldLoadMore && !isLoadingMore && fetchMore) {
        fetchMore()
      }
    },
    [fetchMore, isLoadingMore, hasNextPage]
  )

  const isColumnsListEmpty = useMemo(
    () =>
      validColumnsSpecs.length === 0 ||
      // or there is only 1 columns – checkboxes
      (validColumnsSpecs.length === 1 && !validColumnsSpecs[0].field),
    [validColumnsSpecs]
  )

  const isEmptyHeader = useMemo(
    () => _.every(validColumnsSpecs, ({ header }) => !header),
    [validColumnsSpecs]
  )

  if (isColumnsListEmpty && allAvailableColumns && setVisibleColumns) {
    return (
      <EmptyColumnsList
        allAvailableColumns={allAvailableColumns}
        setVisibleColumns={setVisibleColumns}
      />
    )
  }

  const expandableProps = {
    // !key is important otherwise the data will have wrong groups when switching the tableGroupedBy property
    key: tableGroupedBy,
    rowGroupMode: 'subheader',
    groupRowsBy: tableGroupedBy,
    // perhaps a bug from the library
    // !sortField has to be same as the groupRowsBy
    // otherwise there will be duplicate groups
    sortField: tableGroupedBy,
    expandableRowGroups: true,
    expandedRows,
    rowGroupHeaderTemplate,
    onRowToggle: (e: DataTableRowToggleEvent) =>
      setExpandedRows(e.data as Item[]),
    virtualScrollerOptions: undefined,
  }

  return (
    <StyledDataTable
      headerHeight={headerHeight}
      isLightTheme={isLightTheme}
      hideHeader={isEmptyHeader}
      className={className}
    >
      {loading && <TableProgressBar tableRef={tableRef} />}
      <ReactDataTable
        ref={tableRef}
        value={list}
        rowClassName={rowClassName}
        sortOrder={sortOrder ? 1 : -1}
        scrollable={scrollable}
        scrollHeight={scrollHeight}
        dataKey={dataKey}
        emptyMessage={emptyMessage}
        resizableColumns={resizableColumns}
        showGridlines
        rowGroupMode={rowGroupMode}
        groupRowsBy={groupRowsBy}
        {...(isLoadingMore
          ? {
              footer: <LoadingMoreSpinner />,
            }
          : {
              footer,
              loading,
            })}
        {...(isMultiSort
          ? { sortMode: 'multiple' }
          : { sortMode: 'single', sortField })}
        {...(isVirtualScroll && {
          virtualScrollerOptions: {
            itemSize: 50,
            onScroll: onInfiniteScroll,
          },
          responsiveLayout: 'scroll',
        })}
        {...(_.isFunction(onRowSelect) && {
          rowHover: true,
          onRowSelect,
          selectionMode: 'single',
          selection: currentActiveItem,
        })}
        {...(onMouseEnter && { onRowMouseEnter: e => onMouseEnter(e.data) })}
        {...(onMouseLeave && { onRowMouseLeave: e => onMouseLeave(e.data) })}
        {...(setListConditions && {
          // The 'onSort' prop switches a sorting to a 'controlled' mode
          onSort: eventData => {
            // Save the selected sorting options
            setListConditions(prevState => ({
              ...prevState,
              sortField: eventData.sortField,
              ascOrder: eventData.sortOrder === 1,
            }))
          },
        })}
        // !!https://github.com/primefaces/primereact/issues/3470
        // when columns are reordered, duplicate columns are added
        // reorderableColumns
        {...(tableGroupedBy && { ...expandableProps })}
        pt={pt}
        {...(isCellEditable && { editMode: 'cell' })}
      >
        {renderColumns()}
      </ReactDataTable>
    </StyledDataTable>
  )
}

export default DataTable
